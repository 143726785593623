
















































































import Vue from 'vue'

export default Vue.extend({
  props: {
    image: {
      type: String,
      default: undefined,
    },
    imageContentType: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 160,
    },
  },
  data: () => ({
    blobContent: { image: null } as any,
  }),
  methods: {
    setFileData (event: any, entity: any, field: any, isImage: any) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0]
        if (isImage && !/^image\//.test(file.type)) {
          return
        }
        this.toBase64(file, async (base64Data: any) => {
          entity[field] = base64Data
          entity[`${field}ContentType`] = file.type
          if (this.blobContent && this.blobContent.image) {
            this.base64Resize('data:' + file.type + ';base64,' + base64Data, 1000)
              .then((result: string) => {
                // console.log(
                //   'ImageResize: ' +
                //     this.byteSize(base64Data) +
                //     ' - transformed to: ' +
                //     this.byteSize(result),
                // )
                this.blobContent.image = result
                this.blobContent.imageContentType = 'image/jpeg'
                this.$emit('imageLoaded', this.blobContent)
              })
              .catch((error) => {
                console.error('Error resizing image - using original image')
                console.error(error)

                this.$emit('imageLoaded', this.blobContent)
              })
          }
        })
      }
    },

    toBase64 (file: any, cb: any) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = (e: any) => {
        const base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length)
        cb(base64Data)
      }
    },

    clearInputImage (field: any, fieldContentType: any, idInput: any): void {
      if (this.blobContent && field && fieldContentType) {
        if (Object.prototype.hasOwnProperty.call(this.blobContent, field)) {
          this.blobContent[field] = null
        }
        if (Object.prototype.hasOwnProperty.call(this.blobContent, fieldContentType)) {
          this.blobContent[fieldContentType] = null
        }
        if (idInput) {
          this.$refs[idInput] = undefined
        }
      }
    },
    byteSize (base64String: string) {
      return this.formatAsBytes(this.size(base64String))
    },

    size (value: any) {
      return (value.length / 4) * 3 - this.paddingSize(value)
    },

    endsWith (suffix: any, str: string) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1
    },

    paddingSize (value: any) {
      if (this.endsWith('==', value)) {
        return 2
      }
      if (this.endsWith('=', value)) {
        return 1
      }
      return 0
    },

    formatAsBytes (size: any) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes'
    },
    base64Resize (sourceBase64: any, maxWidth: any) {
      return new Promise<string>(function (resolve, reject) {
        var img = document.createElement('img')
        img.setAttribute('src', sourceBase64)

        img.onload = () => {
          var canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          var ctx = canvas.getContext('2d')
          // var cw = canvas.width
          // var ch = canvas.height

          var maxW = img.width
          if (maxWidth < img.width) {
            maxW = maxWidth
          }
          var maxH = img.height

          var iw = img.width
          var ih = img.height
          var scale = Math.min(maxW / iw, maxH / ih)
          var iwScaled = iw * scale
          var ihScaled = ih * scale
          canvas.width = iwScaled
          canvas.height = ihScaled

          if (ctx) {
            // Fill with white
            ctx.fillStyle = '#FFF'
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            ctx.drawImage(img, 0, 0, iwScaled, ihScaled)
          }
          // 0.7 is the default jpeg quality almost everywhere
          // if you use more, you might end up increasing the fileSize
          const quality = 0.7
          const newBase64 = canvas.toDataURL('image/jpeg', quality)

          // console.log(newBase64)
          // console.log(this.byteSize(newBase64))
          const result = newBase64.split(';base64,')[1]
          resolve(result)
        }
      })
    },
  },
})
